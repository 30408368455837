import React from "react";
import axios from "axios";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";

import { getFiles } from "../../../redux/slices/MainFileSlice";

import HeaderWhite from "../../Header/HeaderWhite";

import styles from "./FirstBlock.module.scss";
import image from "../../../assets/img/MainBlock/image.png";
import downloadSvg from "../../../assets/img/MainBlock/download.svg";

const { REACT_APP_API_DOWNLOAD } = process.env;

const FirstBlock = () => {
  const { t } = useTranslation();
  const { mainFile } = useSelector((state) => state.mainFile);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();
  const titleWithLineBreak = t("mainBlock.title");

  React.useEffect(() => {
    dispatch(getFiles());
  }, []);

  // Обработчик скачивания файла когда на стороне Backend проблема с DEBUG
  const handleDownloadPresEn = async () => {
    try {
      let lnk = mainFile[0] && mainFile[0].file_en;
      let idx = lnk.lastIndexOf("/");
      let filename = lnk.substring(idx + 1);
      const response = await axios.get(
        `${REACT_APP_API_DOWNLOAD}/${filename}`,
        {
          responseType: "blob", // Важно указать responseType: 'blob'
        }
      );

      // Создаем временную ссылку
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Создаем ссылку для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "logistics_report_en.pdf");
      document.body.appendChild(link);

      // Эмулируем клик на ссылке
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  // Обработчик скачивания файла когда на стороне Backend проблема с DEBUG
  const handleDownloadPresRu = async () => {
    try {
      let lnk = mainFile[0] && mainFile[0].file;
      let idx = lnk.lastIndexOf("/");
      let filename = lnk.substring(idx + 1);
      const response = await axios.get(
        `${REACT_APP_API_DOWNLOAD}/${filename}`,
        {
          responseType: "blob", // Важно указать responseType: 'blob'
        }
      );

      // Создаем временную ссылку
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Создаем ссылку для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "logistics_report_ru.pdf");
      document.body.appendChild(link);

      // Эмулируем клик на ссылке
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  return (
    <div className={styles.root}>
      <Container>
        <HeaderWhite />
        <div
          className={classNames(
            "d-flex justify-content-center align-items-center",
            styles.wrapper
          )}
        >
          <div
            className={classNames(
              "d-flex align-items-center",
              styles.titleImageBlock
            )}
          >
            <div className={styles.titleBtnBlock}>
              <div className={styles.titleBlock}>
                <p>Centrum Logistics Group</p>
                <p>
                  {titleWithLineBreak.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < titleWithLineBreak.split("\n").length - 1 && (
                        <br />
                      )}
                    </React.Fragment>
                  ))}
                </p>
              </div>
              <div className={styles.btnPresentationBlock}>
                <div
                  className="d-flex align-items-start"
                  style={{ flexDirection: "column" }}
                >
                  {language === "en" ? (
                    <a href="CLG_corp_eng.pptx" download="CLG_corp_EN.pptx">
                      <img src={downloadSvg} alt="" />
                      {t("mainBlock.downloadBtn")}
                    </a>
                  ) : (
                    <a href="CLG_corp_rus.pptx" download="CLG_corp_RU.pptx">
                      <img src={downloadSvg} alt="" />
                      {t("mainBlock.downloadBtn")}
                    </a>
                  )}
                  {language === "en" ? (
                    <button
                      onClick={handleDownloadPresEn}
                      style={{
                        marginTop: 10,
                        padding: 0,
                        background: "none",
                        border: "none",
                      }}
                    >
                      <img
                        src={downloadSvg}
                        alt=""
                        width={32}
                        style={{ marginRight: 10 }}
                      />
                      <span
                        style={{ color: "#fff", textDecoration: "underline" }}
                      >
                        {t("mainBlock.downloadPres")}
                      </span>
                    </button>
                  ) : (
                    <button
                      onClick={handleDownloadPresRu}
                      style={{
                        marginTop: 10,
                        padding: 0,
                        background: "none",
                        border: "none",
                      }}
                    >
                      <img
                        src={downloadSvg}
                        alt=""
                        width={32}
                        style={{ marginRight: 10 }}
                      />
                      <span
                        style={{ color: "#fff", textDecoration: "underline" }}
                      >
                        {t("mainBlock.downloadPres")}
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.imageBlock}>
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FirstBlock;
