// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfrastructureBlock_root__Fm6ZX {
  padding-top: 40px;
}

@media (max-width: 768px) {
  .InfrastructureBlock_root__Fm6ZX {
    padding-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InfrastructureBlock/InfrastructureBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE;IACE,iBAAA;EACF;AACF","sourcesContent":[".root {\n  padding-top: 40px;\n}\n\n@media (max-width: 768px) {\n  .root {\n    padding-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `InfrastructureBlock_root__Fm6ZX`
};
export default ___CSS_LOADER_EXPORT___;
