import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { getOffice } from "../../redux/slices/OfficeListSlice";

import HeaderBlue from "../Header/HeaderBlue";

import styles from "./OfficeBlock.module.scss";
import image from "../../assets/img/MapBlock/image.png";

const OfficeBlock = () => {
  const { officeList } = useSelector((state) => state.officeList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getOffice());
  }, []);

  const sortedOffice = [...officeList].sort((first, last) => {
    return first.id - last.id;
  });

  console.log(sortedOffice);

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
        <div
          className={classNames(
            "d-flex justify-content-center align-items-center",
            styles.wrapper
          )}
        >
          <div className={styles.mapBlock}>
            <div
              className={classNames(
                "d-flex justify-content-center",
                styles.imageBlock
              )}
            >
              <img src={image} alt="" />
            </div>
            <div className={styles.flagBlock}>
              {sortedOffice.map((office) => (
                <Link
                  to={`/office-detail/${office.id}`}
                  key={office.id}
                  className={classNames(styles.marker)}
                >
                  <img src={office.flag} alt="" width="40" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OfficeBlock;
