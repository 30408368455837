import React from "react";

// Main block
import FirstBlock from "../../components/MainBlock/FirstBlock";
// Map block
import SecondBlock from "../../components/MainBlock/SecondBlock";
// Partners block
import ThirdBlock from "../../components/MainBlock/ThirdBlock";
// Infrastructure block
import FourthBlock from "../../components/MainBlock/FourthBlock";
// Feedback form block
import FifthBlock from "../../components/MainBlock/FifthBlock";
// Footer block
import SixthBlock from "../../components/MainBlock/SixthBlock";

import styles from "./MainPage.module.scss";

const MainPage = () => {
  return (
    <div className={styles.root}>
      <div>
        <FirstBlock />
      </div>
      <div>
        <SecondBlock />
      </div>
      <div>
        <ThirdBlock />
      </div>
      <div>
        <FourthBlock />
      </div>
      <div>
        <FifthBlock />
      </div>
      <div>
        <SixthBlock />
      </div>
    </div>
  );
};

export default MainPage;
