// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceBlock_root__M8\\+a7 {
  padding-top: 40px;
}
.ServiceBlock_root__M8\\+a7 .ServiceBlock_carousel__cu\\+FS button {
  display: none;
}
.ServiceBlock_root__M8\\+a7 .ServiceBlock_carousel__cu\\+FS span {
  display: none;
}

@media (max-width: 768px) {
  .ServiceBlock_root__M8\\+a7 {
    padding-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceBlock/ServiceBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEI;EACE,aAAA;AAAN;AAGI;EACE,aAAA;AADN;;AAMA;EACE;IACE,iBAAA;EAHF;AACF","sourcesContent":[".root {\n  padding-top: 40px;\n\n  .carousel {\n    button {\n      display: none;\n    }\n\n    span {\n      display: none;\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .root {\n    padding-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ServiceBlock_root__M8+a7`,
	"carousel": `ServiceBlock_carousel__cu+FS`
};
export default ___CSS_LOADER_EXPORT___;
