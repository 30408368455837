import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";
import { Calendar4Week } from "react-bootstrap-icons";

import HeaderBlue from "../Header/HeaderBlue";

import { getCareerDetail } from "../../redux/slices/CareerDetailSlice";

import styles from "./CareerDetailBlock.module.scss";
import classNames from "classnames";

const CareerDetailBlock = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.LangSlice);
  const { careerDetail } = useSelector((state) => state.careerDetail);

  const dispatch = useDispatch();
  const params = useParams();

  React.useEffect(() => {
    dispatch(getCareerDetail(params));
  }, []);

  return (
    <div className={styles.careerBlock}>
      <Container>
        <HeaderBlue />
        <div
          className={classNames(
            styles.rootBlock,
            "d-flex align-items-baseline justify-content-between"
          )}
          style={{ marginTop: 80 }}
        >
          <div className={styles.infoBlock}>
            <div>
              {language === "en" ? (
                <h4
                  dangerouslySetInnerHTML={{
                    __html: careerDetail.title_en,
                  }}
                />
              ) : (
                <h4
                  dangerouslySetInnerHTML={{
                    __html: careerDetail.title,
                  }}
                />
              )}
            </div>
            <div>
              {language === "en" ? (
                <ul
                  className={styles.list}
                  dangerouslySetInnerHTML={{
                    __html: careerDetail.desc_en,
                  }}
                />
              ) : (
                <ul
                  className={styles.list}
                  dangerouslySetInnerHTML={{
                    __html: careerDetail.desc,
                  }}
                />
              )}
            </div>
          </div>
          <div>
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSeRp-6lHy924CO2Vmmr0bNQWAxQqplmCHsqI_N5caxy9KrUOg/viewform"
              target="_blank"
              className="btn btn-sm btn-danger"
            >
              {t("career.btn")}
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CareerDetailBlock;
