import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Container } from "react-bootstrap";

import { getAboutUs } from "../../../../redux/slices/AboutUsListSlice";

import HeaderWhite from "../../HeaderWhite";

import styles from "./AboutUsBlock.module.scss";

const AboutUsBlock = () => {
  const { t } = useTranslation();
  const { aboutUsList } = useSelector((state) => state.aboutUsList);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  const sortedAbousUs = [...aboutUsList].sort((first, last) => {
    return first.id - last.id;
  });

  return (
    <div className={styles.root}>
      <Container>
        <HeaderWhite />
        <div
          className={classNames(
            "d-flex justify-content-center align-items-center",
            styles.aboutUsBlock
          )}
        >
          <Card className={styles.card}>
            <Card.Body className={styles.cardBody}>
              <div className={classNames("mb-2", styles.titleBlock)}>
                <p>{t("aboutUsBlock.title")}</p>
              </div>
              {language === "en" ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutUsList[4] && aboutUsList[4].desc_en,
                  }}
                />
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: aboutUsList[4] && aboutUsList[4].desc,
                  }}
                />
              )}
              <div className={styles.brandsBlock}>
                <div className={classNames("mb-2", styles.titleBlock)}>
                  <p>{t("aboutUsBlock.text5")}:</p>
                </div>
                <div
                  className={classNames(
                    "d-flex justify-content-between align-items-center",
                    styles.imgBlock
                  )}
                >
                  {sortedAbousUs.map((obj, index) => {
                    return index === 0 ? null : (
                      <div key={obj.id}>
                        <img src={obj.image_logo} alt="" width={80} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default AboutUsBlock;
