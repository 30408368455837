// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardBlock_card__CpDmj {
  height: 100% !important;
  text-align: center;
  --bs-card-border-width: none;
  --bs-card-border-radius: 25px !important;
}
.CardBlock_card__CpDmj .CardBlock_countBlock__kGqTi {
  font-size: 80px;
  font-weight: bold;
  color: #1b51e3;
}
.CardBlock_card__CpDmj .CardBlock_titleBlock__lFWXs {
  font-size: 18px;
  font-weight: bold;
  color: #1b51e3;
}
.CardBlock_card__CpDmj .CardBlock_infoBlock__zLSqx {
  margin-top: 10px;
  color: #00377c;
}

@media (max-width: 991px) {
  .CardBlock_card__CpDmj .CardBlock_countBlock__kGqTi {
    font-size: 55px;
  }
  .CardBlock_card__CpDmj .CardBlock_titleBlock__lFWXs {
    font-size: 16px;
  }
  .CardBlock_card__CpDmj .CardBlock_infoBlock__zLSqx {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .CardBlock_card__CpDmj .CardBlock_countBlock__kGqTi {
    font-size: 40px;
  }
  .CardBlock_card__CpDmj .CardBlock_titleBlock__lFWXs {
    font-size: 14px;
  }
  .CardBlock_card__CpDmj .CardBlock_infoBlock__zLSqx {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InfrastructureBlock/FirstBlock/CardBlock/CardBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,4BAAA;EACA,wCAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGE;EACE,gBAAA;EACA,cAAA;AADJ;;AAKA;EAEI;IACE,eAAA;EAHJ;EAME;IACE,eAAA;EAJJ;EAOE;IACE,eAAA;EALJ;AACF;AASA;EAEI;IACE,eAAA;EARJ;EAWE;IACE,eAAA;EATJ;EAYE;IACE,eAAA;EAVJ;AACF","sourcesContent":[".card {\n  height: 100% !important;\n  text-align: center;\n  --bs-card-border-width: none;\n  --bs-card-border-radius: 25px !important;\n\n  .countBlock {\n    font-size: 80px;\n    font-weight: bold;\n    color: #1b51e3;\n  }\n\n  .titleBlock {\n    font-size: 18px;\n    font-weight: bold;\n    color: #1b51e3;\n  }\n\n  .infoBlock {\n    margin-top: 10px;\n    color: #00377c;\n  }\n}\n\n@media (max-width: 991px) {\n  .card {\n    .countBlock {\n      font-size: 55px;\n    }\n\n    .titleBlock {\n      font-size: 16px;\n    }\n\n    .infoBlock {\n      font-size: 14px;\n    }\n  }\n}\n\n@media (max-width: 500px) {\n  .card {\n    .countBlock {\n      font-size: 40px;\n    }\n\n    .titleBlock {\n      font-size: 14px;\n    }\n\n    .infoBlock {\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CardBlock_card__CpDmj`,
	"countBlock": `CardBlock_countBlock__kGqTi`,
	"titleBlock": `CardBlock_titleBlock__lFWXs`,
	"infoBlock": `CardBlock_infoBlock__zLSqx`
};
export default ___CSS_LOADER_EXPORT___;
