import { configureStore } from "@reduxjs/toolkit";

import LangSlice from "./slices/LangSlice";
import AboutUsListSlice from "./slices/AboutUsListSlice";
import KeyIndicatorListSlice from "./slices/KeyIndicatorListSlice";
import ExpInfrastructureListSlice from "./slices/ExpInfrastructureListSlice";
import FeedbackSlice from "./slices/FeedbackSlice";
import ServiceListSlice from "./slices/ServiceListSlice";
import InfrastructureSlice from "./slices/InfrastructureSlice";
import TerminalListSlice from "./slices/TerminalListSlice";
import NewsListSlice from "./slices/NewsListSlice";
import NewsDetailSlice from "./slices/NewsDetailSlice";
import OfficeListSlice from "./slices/OfficeListSlice";
import OfficeDetailSlice from "./slices/OfficeDetailSlice";
import CareerListSlice from "./slices/CareerListSlice";
import CareerDetailSlice from "./slices/CareerDetailSlice";
import MainFileSlice from "./slices/MainFileSlice";
import MissionListSlice from "./slices/MissionListSlice";

export const store = configureStore({
  reducer: {
    LangSlice: LangSlice,
    aboutUsList: AboutUsListSlice,
    keyIndicatorList: KeyIndicatorListSlice,
    expInfrastructureList: ExpInfrastructureListSlice,
    feedbackCreate: FeedbackSlice,
    serviceList: ServiceListSlice,
    infrastructureList: InfrastructureSlice,
    terminalList: TerminalListSlice,
    newsList: NewsListSlice,
    newsDetail: NewsDetailSlice,
    officeList: OfficeListSlice,
    officeDetail: OfficeDetailSlice,
    careerList: CareerListSlice,
    careerDetail: CareerDetailSlice,
    mainFile: MainFileSlice,
    missionList: MissionListSlice,
  },
});
