import React from "react";

import ServiceBlock from "../../components/ServiceBlock";

import styles from "./ServicePage.module.scss";

const ServicePage = () => {
  return (
    <div className={styles.root}>
      <ServiceBlock />
    </div>
  );
};

export default ServicePage;
