import React from "react";
import i18n from "i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initReactI18next } from "react-i18next";

import MainPage from "./pages/MainPage";
import AboutUsPage from "./pages/AboutUsPage";
import MissionPage from "./pages/MissionPage";
import ManagementPage from "./pages/ManagementPage";
import KeyIndicatorsPage from "./pages/KeyIndicatorsPage";
import ServicePage from "./pages/ServicePage";
import OfficePage from "./pages/OfficePage";
import OfficeDetailPage from "./pages/OfficeDetailPage";
import InfrastructurePage from "./pages/InfrastructurePage";
import NewsPage from "./pages/NewsPage";
import NewsDetailPage from "./pages/NewsDetailPage";

import enTranslations from "./translations/en.json";
import ruTranslations from "./translations/ru.json";

import CareerPage from "./pages/CareerPage";
import CareerDetailPage from "./pages/CareerDetailPage";

import "./assets/scss/App.scss";

function App() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ru: {
        translation: ruTranslations,
      },
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/news",
      element: <NewsPage />,
    },
    {
      path: "/news-detail/:id",
      element: <NewsDetailPage />,
    },
    {
      path: "/office",
      element: <OfficePage />,
    },
    {
      path: "/office-detail/:id",
      element: <OfficeDetailPage />,
    },
    {
      path: "/about-us",
      element: <AboutUsPage />,
    },
    {
      path: "/mission",
      element: <MissionPage />,
    },
    {
      path: "/management",
      element: <ManagementPage />,
    },
    {
      path: "/key-indicators",
      element: <KeyIndicatorsPage />,
    },
    {
      path: "/service",
      element: <ServicePage />,
    },
    {
      path: "/infrastructure",
      element: <InfrastructurePage />,
    },
    {
      path: "/career",
      element: <CareerPage />,
    },
    {
      path: "/career-detail/:id",
      element: <CareerDetailPage />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
