import React from "react";

import NewsDetailBlock from "../../components/NewsDetailBlock";

import styles from "./NewsDetailPage.module.scss";

const FirstPage = () => {
  return (
    <div className={styles.root}>
      <NewsDetailBlock />
    </div>
  );
};

export default FirstPage;
