// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CareerPage_root__NaTyJ {
  min-height: 100vh;
  background: #284480;
}`, "",{"version":3,"sources":["webpack://./src/pages/CareerPage/CareerPage.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;AACF","sourcesContent":[".root {\n  min-height: 100vh;\n  background: #284480;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CareerPage_root__NaTyJ`
};
export default ___CSS_LOADER_EXPORT___;
