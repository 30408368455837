// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FirstBlock_root__SHKPx {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 134px);
  padding: 40px 0;
}

@media (max-width: 768px) {
  .FirstBlock_root__SHKPx {
    padding: 20px 0 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InfrastructureBlock/FirstBlock/FirstBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,+BAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,mBAAA;EACF;AACF","sourcesContent":[".root {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: calc(100vh - 134px);\n  padding: 40px 0;\n}\n\n@media (max-width: 768px) {\n  .root {\n    padding: 20px 0 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FirstBlock_root__SHKPx`
};
export default ___CSS_LOADER_EXPORT___;
