import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

import HeaderWhite from "../../HeaderWhite";

import styles from "./ManagementBlock.module.scss";

const ManagementBlock = () => {
  const { t } = useTranslation();

  const textWithLineBreak = t("managementBlock.text");

  return (
    <div className={styles.root}>
      <Container>
        <HeaderWhite />
        <div className={styles.wrapper}>
          <div>
            <div className={styles.titleBlock}>
              <p>{t("managementBlock.title")}</p>
            </div>
            <div className={styles.infoBlock}>
              <p>
                {textWithLineBreak.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < textWithLineBreak.split("\n").length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ManagementBlock;
