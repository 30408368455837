import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getTerminal = createAsyncThunk("get/terminal", async () => {
  const resp = await axios.get(
    `${REACT_APP_API_ENDPOINT}/api/v2/posts/terminal/`
  );
  return resp.data;
});

const initialState = {
  terminalList: [],
};

const TerminalListSlice = createSlice({
  name: "TerminalList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getTerminal.fulfilled]: (state, action) => {
      state.terminalList = action.payload;
    },
    [getTerminal.rejected]: () => {
      alert("Get data failed!");
    },
  },
});

export default TerminalListSlice.reducer;
