import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./CardBlock.module.scss";

const CardBlock = ({ title, title_en, desc, desc_en, image }) => {
  const { language } = useSelector((state) => state.LangSlice);

  return (
    <div className={classNames("d-flex", styles.root)}>
      <div
        className={styles.imageBlock}
        style={{
          background: `url(${image}) center / cover no-repeat`,
        }}
      >
        <img src={image} alt="" />
      </div>
      <div className={styles.titleInfoBlock}>
        <div className={styles.titleBlock}>
          {language === "en" ? (
            <p
              dangerouslySetInnerHTML={{
                __html: title_en,
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
        </div>
        <br />
        <div className={styles.infoBlock}>
          {language === "en" ? (
            <p
              dangerouslySetInnerHTML={{
                __html: desc_en,
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardBlock;
