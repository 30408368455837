import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import styles from './SecondBlock.module.scss'

const SecondBlock = ({
	title,
	title_en,
	title_red,
	title_red_en,
	desc,
	desc_en,
	big_image,
	small_image,
	small_image2,
}) => {
	const { language } = useSelector(state => state.LangSlice)

	return (
		<div className={styles.root}>
			<div className={styles.wrapper}>
				<div className={styles.infoBlock}>
					<div className={styles.titleSubtitleBlock}>
						<div className={styles.titleBlock}>
							{language === 'en' ? (
								<p
									dangerouslySetInnerHTML={{
										__html: title_en,
									}}
								/>
							) : (
								<p
									dangerouslySetInnerHTML={{
										__html: title,
									}}
								/>
							)}
						</div>
						<div className={styles.subtitleBlock}>
							{language === 'en' ? (
								<p
									dangerouslySetInnerHTML={{
										__html: title_red_en,
									}}
								/>
							) : (
								<p
									dangerouslySetInnerHTML={{
										__html: title_red,
									}}
								/>
							)}
						</div>
					</div>
					{language === 'en' ? (
						<ul
							className={styles.listItemBlock}
							dangerouslySetInnerHTML={{
								__html: desc_en,
							}}
						/>
					) : (
						<ul
							className={styles.listItemBlock}
							dangerouslySetInnerHTML={{
								__html: desc,
							}}
						/>
					)}
				</div>
				<div className={styles.imagesBlock}>
					{big_image && <img src={big_image} alt='' width={475} height={335} />}
					<div
						className={classNames(
							'd-flex justify-content-between align-items-center',
							styles.imgFlexBlock
						)}
					>
						{small_image && (
							<img src={small_image} alt='' width={227} height={160} />
						)}
						{small_image2 && (
							<img src={small_image2} alt='' width={227} height={160} />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default SecondBlock
