import React from "react";

import CareerDetailBlock from "../../components/CareerDetailBlock";

import styles from "./CareerDetailPage.module.scss";

const CareerDetailPage = () => {
  return (
    <div className={styles.root}>
      <CareerDetailBlock />
    </div>
  );
};

export default CareerDetailPage;
