import React from "react";
import axios from "axios";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";

import { getNewsDetail } from "../../redux/slices/NewsDetailSlice";

import HeaderBlue from "../Header/HeaderBlue";

import styles from "./NewsDetailBlock.module.scss";

const { REACT_APP_API_DOWNLOAD } = process.env;

const FirstBlock = () => {
  const { t } = useTranslation();
  const { newsDetail } = useSelector((state) => state.newsDetail);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();
  const params = useParams();

  React.useEffect(() => {
    dispatch(getNewsDetail(params));
  }, []);

  // Обработчик скачивания файла когда на стороне Backend проблема с DEBUG
  const handleDownloadPresEn = async () => {
    try {
      let lnk = newsDetail.file_en;
      let idx = lnk.lastIndexOf("/");
      let filename = lnk.substring(idx + 1);
      const response = await axios.get(
        `${REACT_APP_API_DOWNLOAD}/${filename}`,
        {
          responseType: "blob", // Важно указать responseType: 'blob'
        }
      );

      // Создаем временную ссылку
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Создаем ссылку для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "logistics_report_en.pdf");
      document.body.appendChild(link);

      // Эмулируем клик на ссылке
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  // Обработчик скачивания файла когда на стороне Backend проблема с DEBUG
  const handleDownloadPresRu = async () => {
    try {
      let lnk = newsDetail.file;
      let idx = lnk.lastIndexOf("/");
      let filename = lnk.substring(idx + 1);
      const response = await axios.get(
        `${REACT_APP_API_DOWNLOAD}/${filename}`,
        {
          responseType: "blob", // Важно указать responseType: 'blob'
        }
      );

      // Создаем временную ссылку
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Создаем ссылку для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "logistics_report_ru.pdf");
      document.body.appendChild(link);

      // Эмулируем клик на ссылке
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  // Обработчик скачивания файла когда на стороне Backend проблема с DEBUG
  const handleDownloadPresUz = async () => {
    try {
      let lnk = newsDetail.file_uz;
      let idx = lnk.lastIndexOf("/");
      let filename = lnk.substring(idx + 1);
      const response = await axios.get(
        `${REACT_APP_API_DOWNLOAD}/${filename}`,
        {
          responseType: "blob", // Важно указать responseType: 'blob'
        }
      );

      // Создаем временную ссылку
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Создаем ссылку для скачивания файла
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "logistics_report_uz.pdf");
      document.body.appendChild(link);

      // Эмулируем клик на ссылке
      link.click();

      // Освобождаем ресурсы
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  return (
    <div className={styles.newsBlock}>
      <Container>
        <HeaderBlue />
        <div className={styles.root}>
          <div className={styles.wrapper}>
            <div className={styles.infoBlock}>
              <div className={styles.titleBlock}>
                {language === "en" ? (
                  <p>{newsDetail.title_en}</p>
                ) : (
                  <p>{newsDetail.title}</p>
                )}
              </div>
              <div className={styles.textBlock}>
                {language === "en" ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newsDetail.full_description_en,
                    }}
                  />
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: newsDetail.full_description,
                    }}
                  />
                )}
              </div>
              {language === "en" ? (
                <div className="mt-3">
                  {newsDetail.file_en ? (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={handleDownloadPresEn}
                    >
                      {t("mainBlock.downloadPres")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <>
                  <div className="mt-3">
                    {newsDetail.file ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleDownloadPresRu}
                      >
                        {t("mainBlock.downloadPres")}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-3">
                    {newsDetail.file_uz ? (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleDownloadPresUz}
                      >
                        O'zbekiston logistikasi bo'yicha hisobotni yuklab olish
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
            {newsDetail.small_picture2 ? (
              <div className={styles.imagesBlock}>
                <img src={newsDetail.big_picture} alt="" width={420} />
                <div
                  className={classNames(
                    "d-flex justify-content-between align-items-center",
                    styles.imgFlexBlock
                  )}
                >
                  <img src={newsDetail.small_picture} alt="" width={200} />
                  <img src={newsDetail.small_picture2} alt="" width={200} />
                </div>
              </div>
            ) : (
              <div className={styles.imagesBlock}>
                <div>
                  <img src={newsDetail.big_picture} alt="" width={420} />
                </div>
                {newsDetail.small_picture ? (
                  <div className="mt-3">
                    <img src={newsDetail.small_picture} alt="" width={420} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FirstBlock;
