import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { getTerminal } from "../../redux/slices/TerminalListSlice";

import HeaderWhite from "../Header/HeaderWhite";
import FirstBlock from "./FirstBlock";
import SecondBlock from "./SecondBlock";

import styles from "./InfrastructureBlock.module.scss";

const InfrastructureBlock = ({ counters }) => {
  const { terminalList } = useSelector((state) => state.terminalList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTerminal());
  }, []);

  const sortedTerminal = [...terminalList].sort((first, last) => {
    return first.id - last.id;
  });

  counters = sortedTerminal;

  return (
    <div className={styles.root}>
      <Container>
        <HeaderWhite />
        <FirstBlock />
        {counters.map((n, index) => (
          <SecondBlock {...n} key={index} />
        ))}
      </Container>
    </div>
  );
};

export default InfrastructureBlock;
