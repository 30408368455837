import React from "react";
import { useSelector } from "react-redux";

import styles from "./CardBlock.module.scss";

const CardBlock = ({ number, time, item, item_en, desc, desc_en }) => {
  const [currVal, setCurrVal] = React.useState(0);

  const { language } = useSelector((state) => state.LangSlice);

  const val = Number(number);

  React.useEffect(() => {
    currVal !== val && setTimeout(setCurrVal, time, currVal + 1);
  }, [currVal]);

  return (
    <div className="d-flex justify-content-center">
      <div>
        <div className="d-flex justify-content-center">
          <div className={styles.circleBlock}>
            <p>
              {currVal} {language === "en" ? <>{item_en}</> : <>{item}</>}
            </p>
          </div>
        </div>
        {language === "en" ? (
          <p
            className={styles.infoBlock}
            dangerouslySetInnerHTML={{
              __html: desc_en,
            }}
          />
        ) : (
          <p
            className={styles.infoBlock}
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CardBlock;
