import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row } from "react-bootstrap";

import HeaderBlue from "../../HeaderBlue";

import styles from "./MissionBlock.module.scss";
import { getMission } from "../../../../redux/slices/MissionListSlice";

const MissionBlock = () => {
  const { t } = useTranslation();
  const { missionList } = useSelector((state) => state.missionList);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMission());
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
        <div className={styles.wrapper}>
          <div className={styles.titleBlock}>
            <p>{t("missionBlock.title")}</p>
          </div>
          <div className={styles.missionBlock}>
            <Row xs={1} md={3} className="g-4">
              {missionList.map((val) => {
                return (
                  <Col>
                    <Card className={styles.card}>
                      <Card.Body className={styles.cardBody}>
                        <div className={styles.cardTitleBlock}>
                          <p>{val.title}</p>
                        </div>
                        <div>
                          {language === "en" ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: val.desc_en,
                              }}
                            />
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: val.desc,
                              }}
                            />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MissionBlock;
