import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getOffice = createAsyncThunk("get/office", async () => {
  const resp = await axios.get(
    `${REACT_APP_API_ENDPOINT}/api/v2/posts/office/`
  );
  return resp.data;
});

const initialState = {
  officeList: [],
};

const OfficeListSlice = createSlice({
  name: "OfficeList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getOffice.fulfilled]: (state, action) => {
      state.officeList = action.payload;
    },
    [getOffice.rejected]: () => {
      alert("Get office failed!");
    },
  },
});

export default OfficeListSlice.reducer;
