// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardBlock_root__beGip {
  min-height: calc(100vh - 135px);
  margin-top: 40px;
}
.CardBlock_root__beGip .CardBlock_imageBlock__H1dBN {
  width: 50%;
}
.CardBlock_root__beGip .CardBlock_imageBlock__H1dBN img {
  display: none;
}
.CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl {
  width: 50%;
  padding: 0 40px;
  color: #00377c;
}
.CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_titleBlock__UyE1M {
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
}
.CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_infoBlock__6ptj5 p {
  max-width: 100%;
  width: 450px;
  text-align: justify;
}

@media (max-width: 768px) {
  .CardBlock_root__beGip {
    min-height: calc(100vh - 85px);
    margin-top: 20px;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl {
    padding: 0 20px;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_titleBlock__UyE1M {
    font-size: 18px;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_infoBlock__6ptj5 {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .CardBlock_root__beGip {
    min-height: calc(100vh - 81px);
    flex-direction: column;
  }
  .CardBlock_root__beGip .CardBlock_imageBlock__H1dBN {
    width: 100%;
    background: none !important;
  }
  .CardBlock_root__beGip .CardBlock_imageBlock__H1dBN img {
    display: block;
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl {
    width: 100%;
    margin-top: 22px;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_titleBlock__UyE1M {
    font-size: 16px;
  }
  .CardBlock_root__beGip .CardBlock_titleInfoBlock__w9UIl .CardBlock_infoBlock__6ptj5 {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceBlock/CardBlock/CardBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,gBAAA;AACF;AACE;EACE,UAAA;AACJ;AACI;EACE,aAAA;AACN;AAGE;EACE,UAAA;EACA,eAAA;EACA,cAAA;AADJ;AAGI;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;AADN;AAKM;EACE,eAAA;EACA,YAAA;EACA,mBAAA;AAHR;;AASA;EACE;IACE,8BAAA;IACA,gBAAA;EANF;EAQE;IACE,eAAA;EANJ;EAQI;IACE,eAAA;EANN;EASI;IACE,eAAA;EAPN;AACF;AAYA;EACE;IACE,8BAAA;IACA,sBAAA;EAVF;EAYE;IACE,WAAA;IACA,2BAAA;EAVJ;EAYI;IACE,cAAA;IACA,WAAA;IACA,aAAA;IACA,iBAAA;EAVN;EAcE;IACE,WAAA;IACA,gBAAA;EAZJ;EAcI;IACE,eAAA;EAZN;EAeI;IACE,eAAA;EAbN;AACF","sourcesContent":[".root {\n  min-height: calc(100vh - 135px);\n  margin-top: 40px;\n\n  .imageBlock {\n    width: 50%;\n\n    img {\n      display: none;\n    }\n  }\n\n  .titleInfoBlock {\n    width: 50%;\n    padding: 0 40px;\n    color: #00377c;\n\n    .titleBlock {\n      font-size: 26px;\n      line-height: 28px;\n      font-weight: bold;\n    }\n\n    .infoBlock {\n      p {\n        max-width: 100%;\n        width: 450px;\n        text-align: justify;\n      }\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .root {\n    min-height: calc(100vh - 85px);\n    margin-top: 20px;\n\n    .titleInfoBlock {\n      padding: 0 20px;\n\n      .titleBlock {\n        font-size: 18px;\n      }\n\n      .infoBlock {\n        font-size: 14px;\n      }\n    }\n  }\n}\n\n@media (max-width: 500px) {\n  .root {\n    min-height: calc(100vh - 81px);\n    flex-direction: column;\n\n    .imageBlock {\n      width: 100%;\n      background: none !important;\n\n      img {\n        display: block;\n        width: 100%;\n        height: 300px;\n        object-fit: cover;\n      }\n    }\n\n    .titleInfoBlock {\n      width: 100%;\n      margin-top: 22px;\n\n      .titleBlock {\n        font-size: 16px;\n      }\n\n      .infoBlock {\n        font-size: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CardBlock_root__beGip`,
	"imageBlock": `CardBlock_imageBlock__H1dBN`,
	"titleInfoBlock": `CardBlock_titleInfoBlock__w9UIl`,
	"titleBlock": `CardBlock_titleBlock__UyE1M`,
	"infoBlock": `CardBlock_infoBlock__6ptj5`
};
export default ___CSS_LOADER_EXPORT___;
