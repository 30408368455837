import React from "react";

import NewsBlock from "../../components/NewsBlock";

import styles from "./NewsPage.module.scss";

const NewsPage = () => {
  return (
    <div className={styles.root}>
      <NewsBlock />
    </div>
  );
};

export default NewsPage;
