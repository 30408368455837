// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagementBlock_root__gG0CJ {
  padding: 40px 0;
}
.ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 134px);
}
.ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_titleBlock__leNuE {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_infoBlock__P21Mg {
  margin-top: 10px;
  color: #fff;
}

@media (max-width: 768px) {
  .ManagementBlock_root__gG0CJ {
    padding: 20px 0;
  }
  .ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_titleBlock__leNuE {
    font-size: 18px;
  }
  .ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_infoBlock__P21Mg {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_titleBlock__leNuE {
    font-size: 16px;
  }
  .ManagementBlock_root__gG0CJ .ManagementBlock_wrapper__OixyK .ManagementBlock_infoBlock__P21Mg {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/AboutUsMenu/ManagementBlock/ManagementBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,+BAAA;AACJ;AACI;EACE,eAAA;EACA,iBAAA;EACA,WAAA;AACN;AAEI;EACE,gBAAA;EACA,WAAA;AAAN;;AAKA;EACE;IACE,eAAA;EAFF;EAKI;IACE,eAAA;EAHN;EAMI;IACE,eAAA;EAJN;AACF;AASA;EAGM;IACE,eAAA;EATN;EAYI;IACE,eAAA;EAVN;AACF","sourcesContent":[".root {\n  padding: 40px 0;\n\n  .wrapper {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    min-height: calc(100vh - 134px);\n\n    .titleBlock {\n      font-size: 26px;\n      font-weight: bold;\n      color: #fff;\n    }\n\n    .infoBlock {\n      margin-top: 10px;\n      color: #fff;\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .root {\n    padding: 20px 0;\n\n    .wrapper {\n      .titleBlock {\n        font-size: 18px;\n      }\n\n      .infoBlock {\n        font-size: 14px;\n      }\n    }\n  }\n}\n\n@media (max-width: 500px) {\n  .root {\n    .wrapper {\n      .titleBlock {\n        font-size: 16px;\n      }\n\n      .infoBlock {\n        font-size: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ManagementBlock_root__gG0CJ`,
	"wrapper": `ManagementBlock_wrapper__OixyK`,
	"titleBlock": `ManagementBlock_titleBlock__leNuE`,
	"infoBlock": `ManagementBlock_infoBlock__P21Mg`
};
export default ___CSS_LOADER_EXPORT___;
