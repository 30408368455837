import React from "react";
import { useSelector } from "react-redux";
import { Card, Col } from "react-bootstrap";

import styles from "./CardBlock.module.scss";

const CardBlock = ({ number, time, title, title_en, desc, desc_en, image }) => {
  const [currVal, setCurrVal] = React.useState(0);

  const { language } = useSelector((state) => state.LangSlice);

  React.useEffect(() => {
    currVal !== number && setTimeout(setCurrVal, time, currVal + 1);
  }, [currVal]);

  return (
    <Col>
      <Card className={styles.card}>
        <Card.Body className={styles.cardBody}>
          <div className={styles.countBlock}>
            <p>{currVal}</p>
          </div>
          {language === "en" ? (
            <p
              className={styles.titleBlock}
              dangerouslySetInnerHTML={{
                __html: title_en,
              }}
            />
          ) : (
            <p
              className={styles.titleBlock}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
          {language === "en" ? (
            <p
              className={styles.infoBlock}
              dangerouslySetInnerHTML={{
                __html: desc_en,
              }}
            />
          ) : (
            <p
              className={styles.infoBlock}
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            />
          )}
        </Card.Body>
        <Card.Img src={image} className={styles.image} />
      </Card>
    </Col>
  );
};

export default CardBlock;
