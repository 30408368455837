// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OfficePage_root__JeHHS {
  min-height: 100vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/OfficePage/OfficePage.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,oEAAA;EACA,sBAAA;AACJ","sourcesContent":[".root {\n    min-height: 100vh;\n    background: url(\"../../assets/img/background.png\") center no-repeat;\n    background-size: cover;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OfficePage_root__JeHHS`
};
export default ___CSS_LOADER_EXPORT___;
