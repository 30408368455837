// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paginator_pagination__-MfjU li {
  --bs-pagination-active-bg: #00377c;
  --bs-pagination-active-border-color: #00377c;
}
.Paginator_pagination__-MfjU li a {
  color: #00377c;
}`, "",{"version":3,"sources":["webpack://./src/components/Paginator/Paginator.module.scss"],"names":[],"mappings":"AACE;EACE,kCAAA;EACA,4CAAA;AAAJ;AAEI;EACE,cAAA;AAAN","sourcesContent":[".pagination {\n  li {\n    --bs-pagination-active-bg: #00377c;\n    --bs-pagination-active-border-color: #00377c;\n\n    a {\n      color: #00377c;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `Paginator_pagination__-MfjU`
};
export default ___CSS_LOADER_EXPORT___;
