import React from "react";

import MissionBlock from '../../components/Header/AboutUsMenu/MissionBlock'

import styles from "./MissionPage.module.scss";

const MissionPage = () => {
  return (
    <div className={styles.root}>
      <MissionBlock />
    </div>
  );
};

export default MissionPage;
