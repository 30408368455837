// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CareerDetailBlock_careerBlock__KhvbK {
  padding: 40px 0;
}
.CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_infoBlock__Ek6TX {
  font-size: 18px;
  color: #00377c;
}
.CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_infoBlock__Ek6TX h4 {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: bold;
}
.CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_infoBlock__Ek6TX .CareerDetailBlock_list__lxGO4 {
  margin-top: 10px;
  padding-left: 20px;
}
.CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_infoBlock__Ek6TX .CareerDetailBlock_list__lxGO4 li {
  list-style: circle;
}

@media (max-width: 991px) {
  .CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_rootBlock__m7f\\+C {
    flex-direction: column;
  }
  .CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_rootBlock__m7f\\+C .CareerDetailBlock_infoBlock__Ek6TX {
    font-size: 14px;
  }
  .CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_rootBlock__m7f\\+C .CareerDetailBlock_infoBlock__Ek6TX h4 {
    font-size: 22px;
  }
  .CareerDetailBlock_careerBlock__KhvbK .CareerDetailBlock_rootBlock__m7f\\+C a {
    margin-top: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CareerDetailBlock/CareerDetailBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;AACJ;AACI;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AACN;AAEI;EACE,gBAAA;EACA,kBAAA;AAAN;AAEM;EACE,kBAAA;AAAR;;AAMA;EAEI;IACE,sBAAA;EAJJ;EAMI;IACE,eAAA;EAJN;EAMM;IACE,eAAA;EAJR;EAQI;IACE,gBAAA;EANN;AACF","sourcesContent":[".careerBlock {\n  padding: 40px 0;\n\n  .infoBlock {\n    font-size: 18px;\n    color: #00377c;\n\n    h4 {\n      margin-bottom: 10px;\n      font-size: 26px;\n      font-weight: bold;\n    }\n\n    .list {\n      margin-top: 10px;\n      padding-left: 20px;\n\n      li {\n        list-style: circle;\n      }\n    }\n  }\n}\n\n@media (max-width: 991px) {\n  .careerBlock {\n    .rootBlock {\n      flex-direction: column;\n\n      .infoBlock {\n        font-size: 14px;\n    \n        h4 {\n          font-size: 22px;\n        }\n      }\n\n      a {\n        margin-top: 20px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"careerBlock": `CareerDetailBlock_careerBlock__KhvbK`,
	"infoBlock": `CareerDetailBlock_infoBlock__Ek6TX`,
	"list": `CareerDetailBlock_list__lxGO4`,
	"rootBlock": `CareerDetailBlock_rootBlock__m7f+C`
};
export default ___CSS_LOADER_EXPORT___;
