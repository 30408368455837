import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";

import { getNews, setFilter } from "../../redux/slices/NewsListSlice";

import HeaderBlue from "../Header/HeaderBlue";
import Paginator from "../Paginator";

import styles from "./NewsBlock.module.scss";

const NewsBlock = () => {
  const { t } = useTranslation();
  const { newsList } = useSelector((state) => state.newsList);
  const { filter } = useSelector((state) => state.newsList);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getNews());
  }, []);

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
        {newsList.results && newsList.results.length === 0 ? (
          <div className={styles.loadingBlock}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className={styles.wrapper}>
              <div>
                <div
                  className={classNames(
                    "d-flex justify-content-between align-items-center",
                    styles.titleLinkBlock
                  )}
                >
                  <div>
                    <p>{t("newsBlock.title")}</p>
                  </div>
                </div>
                <Row xs={1} md={3} className={classNames("g-4", styles.row)}>
                  {newsList.results &&
                    newsList.results.map((news) => (
                      <Col key={news.id} className={styles.col}>
                        <Card className={styles.card}>
                          <Card.Body className={styles.cardBody}>
                            <div className={styles.imageBlock}>
                              <img src={news.big_picture} alt="" height={250} />
                            </div>
                            <div className={styles.titleInfoBlock}>
                              <div className={styles.titleBlock}>
                                {language === "en" ? (
                                  <p>{news.title_en}</p>
                                ) : (
                                  <p>{news.title}</p>
                                )}
                              </div>
                              <div className={styles.infoBlock}>
                                {language === "en" ? (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: news.full_description_en,
                                    }}
                                  />
                                ) : (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: news.full_description,
                                    }}
                                  />
                                )}
                              </div>
                              <div className={styles.linkBlock}>
                                <Link to={`/news-detail/${news.id}`}>
                                  {t("newsBlock.link")}
                                </Link>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
            <Paginator
              filter={filter}
              response={newsList}
              setFilter={setFilter}
              getList={getNews}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default NewsBlock;
