import React from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

import { getExpInfrastructure } from "../../../redux/slices/ExpInfrastructureListSlice";

import styles from "./FourthBlock.module.scss";

const FourthBlock = () => {
  const { t } = useTranslation();
  const { expInfrastructureList } = useSelector(
    (state) => state.expInfrastructureList
  );
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getExpInfrastructure());
  }, []);

  const sortedExpInfrastructure = [...expInfrastructureList].sort(
    (first, last) => {
      return first.id - last.id;
    }
  );

  const titleWithLineBreak = t("infrastructureBlock.title");

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.titleBlock}>
          <p>
            {titleWithLineBreak.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < titleWithLineBreak.split("\n").length - 1 && <br />}
              </React.Fragment>
            ))}
          </p>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.infrastructureBlock}>
            {sortedExpInfrastructure.map((val) => {
              return (
                <div key={val.id} className={classNames(styles.block)}>
                  <div className={styles.adaptiveBlock}>
                    <div className={styles.imageBlock}>
                      <img src={val.image} alt="" width={70} />
                    </div>
                    <div className={styles.infoBlock}>
                      {language === "en" ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: val.desc_en,
                          }}
                        />
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: val.desc,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FourthBlock;
