// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManagementPage_root__uXT29 {
  position: relative;
  min-height: 100vh;
  background: #284480;
}
.ManagementPage_root__uXT29 .ManagementPage_overlay__bBIX8 {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  pointer-events: none;
}
.ManagementPage_root__uXT29 .ManagementPage_overlay__bBIX8 img {
  max-width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/ManagementPage.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,QAAA;EACA,QAAA;EACA,6BAAA;EACA,oBAAA;AACJ;AACI;EACE,eAAA;EACA,YAAA;AACN","sourcesContent":[".root {\n  position: relative;\n  min-height: 100vh;\n  background: #284480;\n\n  .overlay {\n    position: fixed;\n    top: 50%;\n    right: 0;\n    transform: translate(0, -50%);\n    pointer-events: none;\n\n    img {\n      max-width: 100%;\n      height: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ManagementPage_root__uXT29`,
	"overlay": `ManagementPage_overlay__bBIX8`
};
export default ___CSS_LOADER_EXPORT___;
