import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getService = createAsyncThunk("get/service", async () => {
  const resp = await axios.get(
    `${REACT_APP_API_ENDPOINT}/api/v2/posts/service/`
  );
  return resp.data;
});

const initialState = {
  serviceList: [],
};

const ServiceListSlice = createSlice({
  name: "ServiceList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getService.fulfilled]: (state, action) => {
      state.serviceList = action.payload;
    },
    [getService.rejected]: () => {
      alert("Get data failed!");
    },
  },
});

export default ServiceListSlice.reducer;
