import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";

import { getInfrastructure } from "../../../redux/slices/InfrastructureSlice";

import CardBlock from "./CardBlock";

import styles from "./FirstBlock.module.scss";

const FirstBlock = ({ counters }) => {
  const { infrastructureList } = useSelector(
    (state) => state.infrastructureList
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getInfrastructure());
  }, []);

  const sortedInfrastructure = [...infrastructureList].sort((first, last) => {
    return first.id - last.id;
  });

  counters = sortedInfrastructure;

  return (
    <div className={styles.root}>
      <Row xs={1} md={3} className="g-4">
        {counters.map((n, index) => (
          <CardBlock {...n} key={index} />
        ))}
      </Row>
    </div>
  );
};

export default FirstBlock;
