import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

import { getKeyIndicator } from "../../../../redux/slices/KeyIndicatorListSlice";

import HeaderBlue from "../../HeaderBlue";
import CardBlock from "./CardBlock";

import styles from "./KeyIndicatorsBlock.module.scss";

const KeyIndicatorsBlock = ({ counters }) => {
  const { t } = useTranslation();
  const { keyIndicatorList } = useSelector((state) => state.keyIndicatorList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getKeyIndicator());
  }, []);

  const sortedKeyIndicator = [...keyIndicatorList].sort((first, last) => {
    return first.id - last.id;
  });

  counters = sortedKeyIndicator;

  const titleWithLineBreak = t("increaseBlock.title");

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
        <div className={styles.wrapper}>
          <div>
            <div className={styles.titleBlock}>
              {titleWithLineBreak.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < titleWithLineBreak.split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
            <div className={styles.gridBlock}>
              {counters.map((n, index) => (
                <CardBlock {...n} key={index} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default KeyIndicatorsBlock;
