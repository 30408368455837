// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardBlock_circleBlock__0PaFZ {
  position: relative;
  width: 150px;
  height: 150px;
  background: #00377c;
  border-radius: 50%;
}
.CardBlock_circleBlock__0PaFZ p {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-weight: bold;
  color: #fff !important;
}

.CardBlock_infoBlock__Q\\+m9i {
  margin-top: 20px;
  text-align: center;
  color: #00377c;
}

@media (max-width: 768px) {
  .CardBlock_circleBlock__0PaFZ {
    width: 120px;
    height: 120px;
  }
  .CardBlock_circleBlock__0PaFZ p {
    font-size: 18px;
  }
  .CardBlock_infoBlock__Q\\+m9i {
    margin-top: 10px;
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .CardBlock_circleBlock__0PaFZ {
    width: 100px;
    height: 100px;
  }
  .CardBlock_circleBlock__0PaFZ p {
    font-size: 16px;
  }
  .CardBlock_infoBlock__Q\\+m9i {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/AboutUsMenu/KeyIndicatorsBlock/CardBlock/CardBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,kBAAA;EACA,cAAA;AAAF;;AAGA;EACE;IACE,YAAA;IACA,aAAA;EAAF;EAEE;IACE,eAAA;EAAJ;EAIA;IACE,gBAAA;IACA,eAAA;EAFF;AACF;AAKA;EACE;IACE,YAAA;IACA,aAAA;EAHF;EAKE;IACE,eAAA;EAHJ;EAOA;IACE,eAAA;EALF;AACF","sourcesContent":[".circleBlock {\n  position: relative;\n  width: 150px;\n  height: 150px;\n  background: #00377c;\n  border-radius: 50%;\n\n  p {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    margin-right: -50%;\n    transform: translate(-50%, -50%);\n    font-size: 26px;\n    font-weight: bold;\n    color: #fff !important;\n  }\n}\n\n.infoBlock {\n  margin-top: 20px;\n  text-align: center;\n  color: #00377c;\n}\n\n@media (max-width: 768px) {\n  .circleBlock {\n    width: 120px;\n    height: 120px;\n\n    p {\n      font-size: 18px;\n    }\n  }\n\n  .infoBlock {\n    margin-top: 10px;\n    font-size: 14px;\n  }\n}\n\n@media (max-width: 500px) {\n  .circleBlock {\n    width: 100px;\n    height: 100px;\n\n    p {\n      font-size: 16px;\n    }\n  }\n\n  .infoBlock {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleBlock": `CardBlock_circleBlock__0PaFZ`,
	"infoBlock": `CardBlock_infoBlock__Q+m9i`
};
export default ___CSS_LOADER_EXPORT___;
