import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getCareerDetail = createAsyncThunk(
  "get/career/detail",
  async (args) => {
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/career/${args.id}`
    );
    return resp.data;
  }
);

const initialState = {
  careerDetail: {},
};

const CareerDetailSlice = createSlice({
  name: "CareerDetail",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getCareerDetail.fulfilled]: (state, action) => {
      state.careerDetail = action.payload;
    },
    [getCareerDetail.rejected]: () => {
      alert("Get career detail failed!");
    },
  },
});

export default CareerDetailSlice.reducer;
