import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getNewsDetail = createAsyncThunk(
  "get/news/detail",
  async (args) => {
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/post/${args.id}`
    );
    return resp.data;
  }
);

const initialState = {
  newsDetail: {},
};

const NewsDetailSlice = createSlice({
  name: "NewsDetail",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getNewsDetail.fulfilled]: (state, action) => {
      state.newsDetail = action.payload;
    },
    [getNewsDetail.rejected]: () => {
      alert("Get news failed!");
    },
  },
});

export default NewsDetailSlice.reducer;
