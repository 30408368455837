import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";
import { BriefcaseFill } from "react-bootstrap-icons";

import HeaderWhite from "../Header/HeaderWhite";

import { getCareer } from "../../redux/slices/CareerListSlice";

import styles from "./CareerBlock.module.scss";
import logo from "../../assets/img/centrum-logo-blue.png";

const CareerBlock = () => {
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.LangSlice);
  const { careerList } = useSelector((state) => state.careerList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getCareer());
  }, []);

  return (
    <Container className={styles.container}>
      <HeaderWhite />
      <div className={styles.wrapperBlock}>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginBottom: 20 }}
        >
          <p className={styles.title}>{t("career.title")}</p>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSeRp-6lHy924CO2Vmmr0bNQWAxQqplmCHsqI_N5caxy9KrUOg/viewform"
            target="_blank"
            className="btn btn-sm btn-danger"
          >
            {t("career.btn")}
          </Link>
        </div>
        <div className={styles.gridBlock}>
          {careerList.map((item) => {
            return (
              <div
                key={item.id}
                className={classNames(
                  styles.careerBlock,
                  "d-flex justify-content-between"
                )}
              >
                <div>
                  {language === "en" ? (
                    <p
                      className={styles.jobTitle}
                      dangerouslySetInnerHTML={{
                        __html: item.title_en,
                      }}
                    />
                  ) : (
                    <p
                      className={styles.jobTitle}
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />
                  )}
                  <div
                    className={classNames(
                      styles.expBlock,
                      "d-flex align-items-center"
                    )}
                  >
                    <BriefcaseFill size={22} />
                    {language === "en" ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.experience_en,
                        }}
                      />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.experience,
                        }}
                      />
                    )}
                  </div>
                  <Link to={`/career-detail/${item.id}`}>
                    {t("career.link")}
                  </Link>
                </div>
                <div>
                  <img src={logo} alt="" width={80} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default CareerBlock;
