import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel, Container } from "react-bootstrap";

import { getService } from "../../redux/slices/ServiceListSlice";

import HeaderBlue from "../Header/HeaderBlue";
import CardBlock from "./CardBlock";

import styles from "./ServiceBlock.module.scss";

const ServiceBlock = ({ arr }) => {
  const { serviceList } = useSelector((state) => state.serviceList);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getService());
  }, []);

  const sortedService = [...serviceList].sort((first, last) => {
    return first.id - last.id;
  });

  arr = sortedService;

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
      </Container>
      <Carousel data-bs-theme="dark" className={styles.carousel}>
        {sortedService.map((val, index) => {
          return (
            <Carousel.Item key={index}>
              <CardBlock {...val} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ServiceBlock;
