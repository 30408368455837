import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getKeyIndicator = createAsyncThunk(
  "get/key_indicator",
  async () => {
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/key_indicator/`
    );
    return resp.data;
  }
);

const initialState = {
  keyIndicatorList: [],
};

const KeyIndicatorListSlice = createSlice({
  name: "KeyIndicatorList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getKeyIndicator.fulfilled]: (state, action) => {
      state.keyIndicatorList = action.payload;
    },
    [getKeyIndicator.rejected]: () => {
      alert("Get data failed!");
    },
  },
});

export default KeyIndicatorListSlice.reducer;
