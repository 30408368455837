import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const postFeedback = createAsyncThunk(
  "post/feedback",
  async (props, api) => {
    const feedback = api.getState().feedbackCreate;
    const resp = await axios.post(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/feedback/`,
      feedback
    );
    return resp.data;
  }
);

const initialState = {
  name: "",
  phone: "",
  email: "",
  description: "",
};

const FeedbackSlice = createSlice({
  name: "Feedback",
  initialState,
  reducers: {
    setFields: (state, action) => {
      if (action.payload.name === "name") {
        state.name = action.payload.value;
      } else if (action.payload.name === "phone") {
        state.phone = action.payload.value;
      } else if (action.payload.name === "email") {
        state.email = action.payload.value;
      } else if (action.payload.name === "description") {
        state.description = action.payload.value;
      }
    },

    resetState: () => initialState,
  },
  extraReducers: {
    [postFeedback.fulfilled]: () => {
      console.log("Form posted!");
    },
    [postFeedback.rejected]: () => {
      alert("Form failed!");
    },
  },
});

export const { setFields, resetState } = FeedbackSlice.actions;
export default FeedbackSlice.reducer;
