import React from "react";

import OfficeBlock from "../../components/OfficeBlock";

import styles from "./OfficePage.module.scss";

const OfficePage = () => {
  return (
    <div className={styles.root}>
      <OfficeBlock />
    </div>
  );
};

export default OfficePage;
