import React from "react";

import KeyIndicatorsBlock from "../../components/Header/AboutUsMenu/KeyIndicatorsBlock";

import styles from "./KeyIndicatorsPage.module.scss";

const KeyIndicatorsPage = () => {
  return (
    <div className={styles.root}>
      <KeyIndicatorsBlock />
    </div>
  );
};

export default KeyIndicatorsPage;
