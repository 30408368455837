import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getExpInfrastructure = createAsyncThunk(
  "get/exp_infrastructure",
  async () => {
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/exp_log_infrastructure/`
    );
    return resp.data;
  }
);

const initialState = {
  expInfrastructureList: [],
};

const ExpInfrastructureListSlice = createSlice({
  name: "ExpInfrastructureList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getExpInfrastructure.fulfilled]: (state, action) => {
      state.expInfrastructureList = action.payload;
    },
    [getExpInfrastructure.rejected]: () => {
      alert("Get office failed!");
    },
  },
});

export default ExpInfrastructureListSlice.reducer;
