import React from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { getOffice } from "../../redux/slices/OfficeListSlice";
import { getOfficeDetail } from "../../redux/slices/OfficeDetailSlice";

import HeaderBlue from "../Header/HeaderBlue";

import styles from "./OfficeDetailBlock.module.scss";
import lineImage from "../../assets/img/OfficeBlock/Vector24.svg";

const OfficeDetailBlock = () => {
  const { officeList } = useSelector((state) => state.officeList);
  const { officeDetail } = useSelector((state) => state.officeDetail);
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();
  const params = useParams();

  React.useEffect(() => {
    dispatch(getOffice());
    dispatch(getOfficeDetail(params));
  }, []);

  const sortedOffice = [...officeList].sort((first, last) => {
    return first.id - last.id;
  });

  console.log(sortedOffice);

  return (
    <div className={styles.root}>
      <Container>
        <HeaderBlue />
      </Container>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={classNames(styles.block, styles.left)}
            style={{
              background: `url(${officeDetail.background}) center / cover no-repeat`,
            }}
          >
            <img src={officeDetail.background} alt="" />
          </div>
          <div className={classNames(styles.block, styles.right)}>
            <div>
              <div className={styles.titleBlock}>
                <p>{officeDetail.title}</p>
              </div>
              <div className={styles.imageBlock}>
                <img src={lineImage} alt="" />
              </div>
              <div className={styles.infoBlock}>
                {language === "en" ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: officeDetail.address_en,
                    }}
                  />
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: officeDetail.address,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {officeDetail.id === 3 ? (
        <div className={styles.container}>
          <div className={styles.wrapper} style={{ padding: 0 }}>
            <div
              className={classNames(styles.block, styles.left)}
              style={{
                background: `url(${sortedOffice[2].background}) center / cover no-repeat`,
              }}
            >
              <img src={sortedOffice[2].background} alt="" />
            </div>
            <div className={classNames(styles.block, styles.right)}>
              <div>
                <div className={styles.titleBlock}>
                  <p>{sortedOffice[2].title}</p>
                </div>
                <div className={styles.imageBlock}>
                  <img src={lineImage} alt="" />
                </div>
                <div className={styles.infoBlock}>
                  {language === "en" ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sortedOffice[2].address_en,
                      }}
                    />
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sortedOffice[2].address,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OfficeDetailBlock;
