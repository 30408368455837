import React from "react";

import InfrastructureBlock from "../../components/InfrastructureBlock";

import styles from "./InfrastructurePage.module.scss";

const InfrastructurePage = () => {
  return (
    <div className={styles.root}>
      <InfrastructureBlock />
    </div>
  );
};

export default InfrastructurePage;
