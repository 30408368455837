import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Card, Toast } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";

import {
  postFeedback,
  setFields,
  resetState,
} from "../../../redux/slices/FeedbackSlice";

import styles from "./FifthBlock.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const FifthBlock = () => {
  const [notification, setNotification] = React.useState(false);

  const { t } = useTranslation();
  const { name, phone, email, description } = useSelector(
    (state) => state.feedbackCreate
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(postFeedback()).unwrap();
    await setNotification(!notification);
    await dispatch(resetState());
    navigate(`/`);
  };

  const toggleNotification = () => setNotification(!notification);

  return (
    <div className={styles.root}>
      <Toast
        show={notification}
        onClose={toggleNotification}
        className={styles.notification}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto d-flex align-items-center">
            {t("notification.title")}
            <Bell className="ms-1" size={15} />
          </strong>
        </Toast.Header>
        <Toast.Body>{t("notification.message")}</Toast.Body>
      </Toast>
      <div
        className={classNames(
          "d-flex justify-content-center align-items-center",
          styles.wrapper
        )}
      >
        <Card className={styles.card}>
          <Card.Body className={styles.cardBody}>
            <div className={styles.titleBlock}>
              <p>{t("feedbackBlock.title")}</p>
              <p>{t("feedbackBlock.subTitle")}</p>
            </div>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className={styles.feedbackFormBlock}
            >
              <div className={styles.fieldBlock}>
                <input
                  type="text"
                  name="name"
                  value={name}
                  required
                  placeholder={t("feedbackBlock.name")}
                  className="w-50"
                  onChange={(e) => {
                    dispatch(
                      setFields({
                        name: "name",
                        value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className={styles.fieldBlock}>
                <input
                  type="number"
                  name="phone"
                  value={phone}
                  required
                  placeholder={t("feedbackBlock.phone")}
                  className="w-50"
                  onChange={(e) => {
                    dispatch(
                      setFields({
                        name: "phone",
                        value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className={styles.fieldBlock}>
                <input
                  type="email"
                  name="email"
                  value={email}
                  required
                  placeholder="E-mail"
                  className="w-50"
                  onChange={(e) => {
                    dispatch(
                      setFields({
                        name: "email",
                        value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className={styles.fieldBlock}>
                <textarea
                  name="description"
                  value={description}
                  rows="3"
                  required
                  placeholder={t("feedbackBlock.textField")}
                  className="w-100"
                  onChange={(e) => {
                    dispatch(
                      setFields({
                        name: "description",
                        value: e.target.value,
                      })
                    );
                  }}
                ></textarea>
              </div>
              <div
                className={classNames(
                  "d-flex justify-content-center",
                  styles.btnBlock
                )}
              >
                <button type="submit">{t("feedbackBlock.sendBtn")}</button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default FifthBlock;
