import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getFiles = createAsyncThunk("get/files", async () => {
  const resp = await axios.get(`${REACT_APP_API_ENDPOINT}/api/v2/posts/files/`);
  return resp.data;
});

const initialState = {
  mainFile: [],
};

const MailFileSlice = createSlice({
  name: "MainFile",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getFiles.fulfilled]: (state, action) => {
      state.mainFile = action.payload;
    },
    [getFiles.rejected]: () => {
      alert("Get office failed!");
    },
  },
});

export default MailFileSlice.reducer;
