import React from "react";
import { useDispatch } from "react-redux";
import { Pagination } from "react-bootstrap";

import styles from "./Paginator.module.scss";

const Paginator = ({ filter, response, setFilter, getList }) => {
  const [pageList, setPageList] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!response) return;
    let arr = [];
    for (let i = 0; i < response.count / 9; i++) {
      arr.push(i + 1);
    }
    setPageList(arr);
  }, [response]);

  React.useEffect(() => {
    dispatch(getList());
  }, [filter.page]);

  return (
    <Pagination className={styles.pagination}>
      {pageList.map((value) => {
        return (
          <Pagination.Item
            key={value}
            onClick={() => dispatch(setFilter({ name: "page", value: value }))}
            active={filter.page === value}
          >
            {value}
          </Pagination.Item>
        );
      })}
    </Pagination>
  );
};

export default Paginator;
