import React from "react";

import AboutUsBlock from "../../components/Header/AboutUsMenu/AboutUsBlock";

import styles from "./AboutUsPage.module.scss";

const AboutUsPage = () => {
  return (
    <div className={styles.root}>
      <AboutUsBlock />
    </div>
  );
};

export default AboutUsPage;
