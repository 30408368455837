import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";

import { setLang } from "../../../redux/slices/LangSlice";

import styles from "./HeaderWhite.module.scss";
import logo from "../../../assets/img/centrum-logo.png";

const HeaderWhite = () => {
  const { t, i18n } = useTranslation();
  const { language } = useSelector((state) => state.LangSlice);

  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    dispatch(setLang(lng));
  };

  React.useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  }, [language]);

  const externalUrl = "https://websheet.centrum-logistics.com";

  return (
    <div className={styles.root}>
      <Navbar expand="lg" className={styles.navbar}>
        <Navbar.Brand className={styles.navbarBrand}>
          <div
            className={classNames(
              "d-flex align-items-center",
              styles.LogoTitleBlock
            )}
          >
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
            <p>
              {t("header.logoTitle")} <br />
              “Centrum Logistics Group”
            </p>
          </div>
        </Navbar.Brand>
        <div className={styles.adaptiveBtn}>
          <Dropdown className={styles.adaptiveDropdown}>
            <Dropdown.Toggle className={styles.dropdownBtn}>
              {t("header.language")}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item
                onClick={() => changeLanguage("en")}
                className={styles.dropdownItem}
              >
                EN
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeLanguage("ru")}
                className={styles.dropdownItem}
              >
                RU
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={styles.toggleMenu}
          />
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={classNames(styles.nav, "me-auto")}>
            <NavDropdown title={t("header.aboutUs")} id="basic-nav-dropdown">
              <Link to="/about-us" className="dropdown-item">
                {t("header.aboutUsMenu.aboutUs")}
              </Link>
              <Link to="/mission" className="dropdown-item">
                {t("header.aboutUsMenu.mission")}
              </Link>
              <Link to="/management" className="dropdown-item">
                {t("header.aboutUsMenu.management")}
              </Link>
              <Link to="/key-indicators" className="dropdown-item">
                {t("header.aboutUsMenu.keyIndicators")}
              </Link>
            </NavDropdown>
            <Link to="/service" className="nav-link">
              {t("header.services")}
            </Link>
            <Link to="/office" className="nav-link">
              {t("header.offices")}
            </Link>
            <Link to="/infrastructure" className="nav-link">
              {t("header.infrastructure")}
            </Link>
            <Link to="/news" className="nav-link">
              {t("header.news")}
            </Link>
            <Link to="/career" className="nav-link">
              {t("header.career")}
            </Link>
            <Button
              href={externalUrl}
              variant="secondary"
              size="sm"
              style={{ marginLeft: 8 }}
            >
              MyCentrum
            </Button>
          </Nav>
          <Dropdown className={styles.dropdown}>
            <Dropdown.Toggle className={styles.dropdownBtn}>
              {t("header.language")}
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              <Dropdown.Item
                onClick={() => changeLanguage("en")}
                className={styles.dropdownItem}
              >
                EN
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => changeLanguage("ru")}
                className={styles.dropdownItem}
              >
                RU
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default HeaderWhite;
