import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

import styles from "./SecondBlock.module.scss";
import mapImg from "../../../assets/img/MapBlock/image.png";
import mapImgTwo from "../../../assets/img/MapBlock/image_2.png";

const SecondBlock = () => {
  const [showFirstButton, setShowFirstButton] = React.useState(true);
  const [showSecondButton, setShowSecondButton] = React.useState(false);

  const { t } = useTranslation();

  const titleWithLineBreak = t("mapBlock.title");
  const textWithLineBreak = t("mapBlock.markTitle7");
  const textWithLineBreak2 = t("mapBlock.markTitle8");

  const handleFirstButtonClick = () => {
    setShowFirstButton(false);
    setShowSecondButton(true);
  };

  const handleSecondButtonClick = () => {
    setShowFirstButton(true);
    setShowSecondButton(false);
  };

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.titleBlock}>
          <p>
            {titleWithLineBreak.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < titleWithLineBreak.split("\n").length - 1 && <br />}
              </React.Fragment>
            ))}
          </p>
        </div>
        {showFirstButton && (
          <div
            className={classNames("d-flex align-items-center", styles.mapBlock)}
          >
            <div className={styles.imgBlock}>
              <img src={mapImg} alt="" />
            </div>
            <div className={styles.infoBlock}>
              <ul>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle1")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle2")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle3")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle4")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle5")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>{t("mapBlock.markTitle6")}</span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>
                    {textWithLineBreak.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < textWithLineBreak.split("\n").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </span>
                </li>
                <li className="d-flex align-items-center">
                  <div className={styles.circle}></div>
                  <span>
                    {textWithLineBreak2.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < textWithLineBreak2.split("\n").length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
        {showSecondButton && (
          <div
            className={classNames("d-flex align-items-center", styles.mapBlock)}
          >
            <div
              className={classNames(
                "d-flex justify-content-center",
                styles.imgBlock
              )}
            >
              <img src={mapImgTwo} alt="" />
            </div>
          </div>
        )}
        {showFirstButton && (
          <div
            className={classNames(
              "d-flex justify-content-end",
              styles.btnBlock
            )}
          >
            <button onClick={handleFirstButtonClick}>
              <span className="d-flex align-items-center">
                <span>{t("mainBlock.geographyBtn")}</span>
                <CaretRightFill className="ms-1" />
              </span>
            </button>
          </div>
        )}
        {showSecondButton && (
          <div
            className={classNames(
              "d-flex justify-content-end",
              styles.btnBlock
            )}
          >
            <button onClick={handleSecondButtonClick}>
              <span className="d-flex align-items-center">
                <CaretLeftFill />
                <span className="ms-1">{t("mainBlock.geographyBtn")}</span>
              </span>
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default SecondBlock;
