import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getInfrastructure = createAsyncThunk(
  "get/infrastructure",
  async () => {
    const resp = await axios.get(
      `${REACT_APP_API_ENDPOINT}/api/v2/posts/infrastructure/`
    );
    return resp.data;
  }
);

const initialState = {
  infrastructureList: [],
};

const InfrastructureListSlice = createSlice({
  name: "infrastructureList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getInfrastructure.fulfilled]: (state, action) => {
      state.infrastructureList = action.payload;
    },
    [getInfrastructure.rejected]: () => {
      alert("Get office failed!");
    },
  },
});

export default InfrastructureListSlice.reducer;
