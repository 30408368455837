import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en",
};

const LangSlice = createSlice({
  name: "lang",
  initialState: initialState,
  reducers: {
    setLang: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setLang } = LangSlice.actions;
export default LangSlice.reducer;
