import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getCareer = createAsyncThunk("get/career", async () => {
  const resp = await axios.get(
    `${REACT_APP_API_ENDPOINT}/api/v2/posts/career/`
  );
  return resp.data;
});

const initialState = {
  careerList: [],
};

const CareerListSlice = createSlice({
  name: "CareerList",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: {
    [getCareer.fulfilled]: (state, action) => {
      state.careerList = action.payload;
    },
    [getCareer.rejected]: () => {
      alert("Get career list failed!");
    },
  },
});

export default CareerListSlice.reducer;
