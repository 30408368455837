import React from "react";

import ManagementBlock from "../../components/Header/AboutUsMenu/ManagementBlock";

import styles from "./ManagementPage.module.scss";
import image from "../../assets/img/ManagementBlock/image.png";

const ManagementPage = () => {
  return (
    <div className={styles.root}>
      <ManagementBlock />
      <div className={styles.overlay}>
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default ManagementPage;
