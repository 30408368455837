import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

import styles from "./ThirdBlock.module.scss";

const ThirdBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Container className={styles.container}>
        <p>{t("partnersBlock.title")}</p>
      </Container>
      <div className={styles.slider}>
        <div className={styles.slideTrack}>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/1.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/2.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/3.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/4.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/5.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/6.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/7.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/9.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/10.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/11.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/12.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/13.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/14.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/15.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/16.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/17.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/19.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/20.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/21.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/22.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/23.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/24.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/26.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/27.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/1.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/2.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/3.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/4.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/5.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/6.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/7.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/9.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/10.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/11.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/12.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/13.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/14.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/15.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/16.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/17.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/19.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/20.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/21.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/22.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/23.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/24.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/26.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div className={styles.slide}>
            <img
              src={require("../../../assets/img/Partners/27.png")}
              height="100"
              width="250"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdBlock;
