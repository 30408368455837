import React from "react";

import OfficeDetailBlock from "../../components/OfficeDetailBlock";

import styles from "./OfficeDetailPage.module.scss";

const OfficeDetailPage = () => {
  return (
    <div className={styles.root}>
      <OfficeDetailBlock />
    </div>
  );
};

export default OfficeDetailPage;
