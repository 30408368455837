import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const { REACT_APP_API_ENDPOINT } = process.env;

export const getNews = createAsyncThunk("get/news", async (params, api) => {
  const newsData = api.getState().newsList;
  const filter = newsData.filter;
  const resp = await axios.get(
    `${REACT_APP_API_ENDPOINT}/api/v2/posts/post/?p=${filter.page}`
  );
  return resp.data;
});

const initialState = {
  newsList: [],
  filter: {
    page: 1,
  },
};

const NewsListSlice = createSlice({
  name: "NewsList",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      if (action.payload.name === "page")
        state.filter.page = action.payload.value;
    },

    resetState: () => initialState,
  },
  extraReducers: {
    [getNews.fulfilled]: (state, action) => {
      state.newsList = action.payload;
    },
    [getNews.rejected]: () => {
      alert("Get news failed!");
    },
  },
});

export const { setFilter } = NewsListSlice.actions;
export default NewsListSlice.reducer;
