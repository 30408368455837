import React from "react";

import CareerBlock from "../../components/CareerBlock";

import styles from "./CareerPage.module.scss";

const CareerPage = () => {
  return (
    <div className={styles.root}>
      <CareerBlock />
    </div>
  );
};

export default CareerPage;
